import Amplify, { Auth, API, graphqlOperation, Storage } from "aws-amplify"
import {
  Authenticator,
  SignIn,
  ConfirmSignUp,
  Greetings,
  SignUp,
  ForgotPassword,
} from "aws-amplify-react"
import CustomSignUp from "../../../components/CustomSignUp"
import {
  createProject,
  updateProject,
  createRoom,
} from "../../../graphql/mutations"

import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import awsExports from "../../../aws-exports"
import { Hub } from "aws-amplify"
import { listRooms } from "../../../graphql/queries"
import { deleteRoom } from "../../../graphql/mutations"
import AdminMenu from "../../../components/AdminMenu"

import axios from "axios"

Amplify.configure(awsExports)

const SignupLink = props => (
  <div>
    {/* {props.authState} */}
    {props.authState === "signIn" && (
      <div>
        <button onClick={() => props.onStateChange("signUp")}>
          Sign Up here
        </button>
      </div>
    )}
  </div>
)

const AdminRoomsPage = () => {
  const [user, setUser] = useState(null)
  const [userPool, setUserPool] = useState(null)
  const [rooms, setRooms] = useState([])
  const [roomCount, setRoomCount] = useState(1)

  useEffect(() => {
    if (!user) {
      getUser()
    }
    fetchRooms()
    console.log("roomcount: ", roomCount)
  }, [])

  async function getUser() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      console.log("user: ", user)
      //check for user pool group
      console.log(
        "User Pool Group: ",
        user.signInUserSession.accessToken.payload["cognito:groups"]
      )
      // console.log("attributes: ", user.attributes)
      console.log(
        "Is Admin: ",
        user.signInUserSession.accessToken.payload["cognito:groups"].includes(
          "Admins"
        )
      )
      setUser(user)
    } catch (err) {
      console.log("Error: ", err)
    }
  }

  Hub.listen("auth", data => {
    switch (data.payload.event) {
      case "signIn":
        console.log("user signed in")
        getUser()
        break
      case "signUp":
        console.log("user signed up")
        break
      case "signOut":
        console.log("user signed out")
        break
      case "signIn_failure":
        console.log("user sign in failed")
        break
      case "configured":
        console.log("the Auth module is configured")
    }
  })

  async function fetchRooms() {
    try {
      const projectData = await API.graphql(graphqlOperation(listRooms, {limit: 10000}))
      const rooms = projectData.data.listRooms.items
      setRooms(rooms)
      console.log("rooms: ", rooms)
    } catch (err) {
      console.log("error fetching rooms: ", err)
    }
  }

  function handleAuthStateChange(state) {
    if (state === "signedIn") {
      console.log("running handle auth change")
      getUser()
    }
  }

  async function deleteThisRoom(id) {
    try {
      const room = { id: id }
      console.log("room: ", room)
      const deletedRoom = await API.graphql(
        graphqlOperation(deleteRoom, { input: room })
      )
      console.log("deleted room: ", deletedRoom)
      fetchRooms()
    } catch (err) {
      console.log("error deleting room:", err)
    }
  }

  const RoomList = props => {
    // console.log("auth-state: ", props.authState)
    // console.log("auth-data: ", props.authData)
    if (
      props.authState === "signedIn" &&
      user &&
      user.signInUserSession.accessToken.payload["cognito:groups"].includes(
        "Admins"
      )
    ) {
      if (rooms.length > 0) {
        return (
          <div>
            <AdminMenu />
            <section>
              {rooms.map((room, index) => (
                <div
                  key={room.id ? room.id : index}
                  style={{
                    border: "1px solid black",
                    margin: "1rem",
                  }}
                >
                  <li key={room.id}>Hubs ID: {room.hubsId}</li>
                  {room.project ? (
                    <li>Belongs to project: {room.project.title}</li>
                  ) : (
                    <error style={{ color: "red" }}>
                      This room does not belong to any project!
                    </error>
                  )}
                  <div>
                    <button onClick={() => deleteThisRoom(room.id)}>
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </section>
          </div>
        )
      } else {
        return <Link to="/partner/submit">Submit a project</Link>
      }
    } else return <error>You don't have permission to view this page. </error>
  }

  return (
    <section>
      <div className="authenticator">
        <Authenticator
          hideDefault={true}
          onStateChange={handleAuthStateChange()}
        >
          <RoomList />

          <SignIn />
          {/* <CustomSignUp /> */}
          {/* <SignUp signUpConfig={mySignUpConfig} /> */}
          <CustomSignUp />
          <ConfirmSignUp />
          <ForgotPassword />
          <SignupLink />
        </Authenticator>
      </div>
      <SEO title="Contribute" />
      <Link to="/">Go back to the homepage</Link>
    </section>
  )
}

export default AdminRoomsPage
